import React from "react";
import SideBarSection from "./SideBarSection";
import Accordion from "react-bootstrap/Accordion";
// A sidebar has many sections
const sbar = (props) => {
  return (
    <Accordion defaultActiveKey="0" className="p-0">
      <SideBarSection title="Discussion" ekey={0}>
      </SideBarSection>
      <SideBarSection title="Data" ekey={1}>
      </SideBarSection>
    </Accordion>
  )

}

export default sbar
