//A section holds many elements
import React from "react"
import SideElement from "./SideBarElement";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import cardStyles from "./SideBarSection.module.scss";


export default(props) => {
  return(
    <Card className='p-0'>
      <Accordion.Toggle as={Card.Header} eventKey={props.ekey} className={
        [cardStyles.SideBarSectionCardHead , "p-0"].join(" ")}>
        {props.title}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={props.ekey}>
        <Card.Body>{props.children}</Card.Body>
      </Accordion.Collapse>
    </Card>
)

}
