import React from "react";
import Layout from "../components/UI/Layout/Layout"
// import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
import Sidebar from "../components/UI/Sidebar/Sidebar"
import Split from "../components/UI/ContentArea/Split/Split"
import FirstPlot from "../components/Plots/FirstPlot/Firstplot"

export default() =>  {
  return (
    <Layout>
      <Split left={<Sidebar/>} right={<FirstPlot/>}/>
    </Layout>
      );
}
