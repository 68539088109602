//This content area splits the output in two columns
//it takes a left and right component and sets it there.
import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container';

export default(props) => {
  return (
    <Container>
      <Row >
        <Col>
          {props.left}
        </Col>
        <Col>
          {props.right}
        </Col>
      </Row>
    </Container>
  );

}
